<template>
    <div class="container top-0 position-sticky z-index-sticky">
        <div class="row">
            <div class="col-12">
                <navtips btn-background="bg-gradient-primary" />
            </div>
        </div>
    </div>
    <div class="pt-5 m-3 page-header align-items-start pb-11 border-radius-lg">
        <span class="mask bg-gradient-header opacity-6"></span>
        <div class="container">
            <div class="row justify-content-center">
                <!-- <div class="mx-auto text-center col-lg-5">
            <h1 class="mt-5 mb-2 text-white">Welcome to Sortika!</h1>
            <p class="text-white text-lead">
              Save, Invest, Borrow & Lend.
            </p>
          </div> -->
            </div>
        </div>
    </div>
    <div class="container">
        <div class="row mt-lg-n10 mt-md-n11 mt-n10 justify-content-center">
            <div class="mx-auto col-xl-4 col-lg-5 col-md-7">
                <div class="card z-index-0">
                    <div class="pb-0 card-header text-start">
                        <h3 class="font-weight-bolder text-success text-gradient">
                            Borrowing Explainer
                        </h3>
                        <p class="mb-0">Watch the video to learn more</p>
                    </div>
                    <div class="card-body">
                        <div>
                            <div>
                                <iframe
                                    style="width: 100%; height: 12rem; object-fit: contain; margin-top: 1rem; margin-bottom: 1rem; border-radius: 12px;"
                                    src="https://www.youtube.com/embed/7e4MWin6cro"
                                    title="How To Become Your Own Bank 2022" frameborder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowfullscreen></iframe>
                            </div>
                            <!-- <div style="margin-top: 10px; margin-bottom: 20px" class="row align-items-center">
                                <div>
                                    <nav-pill />
                                </div>
                            </div> -->
                            <div class="explainer-body">
                                <div class="pb-0 text-explainer">
                                    <p class="mb-0" style="padding: 5px">You can borrow money from thousands of lenders
                                        on the app and listed lenders. You can send your loan
                                        offer and will be processed within minutes. Most lenders on the application are
                                        impressed by your savings
                                        rate and your loans are likely to be approved faster and get higher limits from
                                        lenders if you are an ardent
                                        saver. Recommended for users who wish to start borrowing responsibly and
                                        sustainably.</p>
                                </div>
                            </div>
                            <div style="display:flex">
                                <router-link style="width: 50%" :to="{ name: 'Product' }">
                                    <soft-button class="my-4 mb-2" variant="gradient" color="dark" full-width>Back
                                    </soft-button>
                                </router-link>
                                <div style="margin-left: 10px"></div>
                                <router-link style="width: 50%" :to="{ name: 'Borrower Information' }">
                                    <soft-button class="my-4 mb-2" variant="gradient" color="success" full-width>Get a
                                        Loan
                                    </soft-button>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <app-footer />
</template>

<script>
import Navtips from "@/examples/PageLayout/Navtips.vue";
import AppFooter from "@/examples/PageLayout/Footer.vue";
import SoftButton from "@/components/SoftButton.vue";
// import NavPill from "./NavPill.vue";

const body = document.getElementsByTagName("body")[0];
import { mapMutations } from "vuex";

export default {
    name: "Borrowing Explainer",
    components: {
        Navtips,
        AppFooter,
        SoftButton,
        // NavPill,
    },
    created() {
        this.toggleEveryDisplay();
        this.toggleHideConfig();
        body.classList.remove("bg-gray-100");
    },
    beforeUnmount() {
        this.toggleEveryDisplay();
        this.toggleHideConfig();
        body.classList.add("bg-gray-100");
    },
    methods: {
        ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
    },
};
</script>
