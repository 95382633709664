<template>
  <footer class="py-5 footer">
    <div class="container">
      <div class="row">
        <!-- <div class="mx-auto mb-4 text-center col-lg-8">
          <a href="javascript:;" target="_blank" class="mb-2 text-secondary me-xl-5 me-3 mb-sm-0">
            About Us
          </a>
          <a href="javascript:;" target="_blank" class="mb-2 text-secondary me-xl-5 me-3 mb-sm-0">
            FAQs
          </a>
          <a href="javascript:;" target="_blank" class="mb-2 text-secondary me-xl-5 me-3 mb-sm-0">
            Packages
          </a>
          <a href="javascript:;" target="_blank" class="mb-2 text-secondary me-xl-5 me-3 mb-sm-0">
            Terms and Conditions
          </a>
        </div> -->
        <div class="mx-auto mt-2 mb-4 text-center col-lg-8">
          <a href="javascript:;" target="_blank" class="text-secondary me-xl-4 me-4">
            <span class="text-lg fab fa-facebook"></span>
          </a>
          <a href="javascript:;" target="_blank" class="text-secondary me-xl-4 me-4">
            <span class="text-lg fab fa-twitter"></span>
          </a>
          <a href="javascript:;" target="_blank" class="text-secondary me-xl-4 me-4">
            <span class="text-lg fab fa-linkedin"></span>
          </a>
          <a href="https://www.youtube.com/@sortikaapp" target="_blank" class="text-secondary me-xl-4 me-4">
            <span class="text-lg fab fa-youtube"></span>
          </a>
        </div>
      </div>
      <div class="row">
        <div class="mx-auto mt-1 text-center col-8">
          <p class="mb-0 text-secondary">
            Copyright © {{ new Date().getFullYear() }} Sortika App.
          </p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "app-footer"
};
</script>
