<template>
    <div class="container top-0 position-sticky z-index-sticky">
        <div class="row">
            <div class="col-12">
                <navtips btn-background="bg-gradient-primary" />
            </div>
        </div>
    </div>
    <main class="mt-0 main-content main-content-bg">
        <section>
            <div class="page-header min-vh-75">
                <div class="container">
                    <div class="row">
                        <div class="mx-auto col-xl-4 col-lg-5 col-md-6 d-flex flex-column">
                            <div class="mt-8 card card-plain">
                                <div class="tip-body">
                                    <div class="pb-0 text-qr">
                                        <div class="pb-0 card-header-tip text-start">
                                            <h3 class="font-weight-bolder text-success text-gradient"
                                                style="margin-bottom: 1.4rem">
                                                Save automatically
                                            </h3>
                                            <p class="mb-0" style="padding-bottom: 1.6rem;">Setting up automatic savings
                                                or
                                                investing can help you save
                                                more money without having to
                                                think about it. You can do this by
                                                setting up automatic transfers from
                                                your checking account to your
                                                savings account or investment
                                                account, or by enrolling in a
                                                workplace retirement plan that
                                                automatically deducts
                                                contributions from your paycheck.</p>
                                        </div>
                                    </div>
                                </div>
                                <div style="display:flex">
                                    <router-link style="width: 100%" :to="{ name: 'Product' }">
                                        <soft-button class="my-4 mb-2" variant="gradient" color="success"
                                            full-width>Continue
                                        </soft-button>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="top-0 oblique-tip position-absolute h-100 d-md-block d-none me-n8">
                                <div class="bg-cover oblique-image position-absolute fixed-top ms-auto h-100 z-index-0 ms-n8"
                                    :style="{
                                        backgroundImage:
                                            'url(' +
                                            require('@/assets/img/curved-images/curved4.jpg') +
                                            ')',
                                    }"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
    <app-footer />
</template>

<script>
import Navtips from "@/examples/PageLayout/Navtips.vue";
import AppFooter from "@/examples/PageLayout/Footer.vue";
import SoftButton from "@/components/SoftButton.vue";

const body = document.getElementsByTagName("body")[0];
import { mapMutations } from "vuex";

export default {
    name: "SignIn",
    components: {
        Navtips,
        AppFooter,
        SoftButton,
    },
    created() {
        this.toggleEveryDisplay();
        this.toggleHideConfig();
        body.classList.remove("bg-gray-100");
    },
    beforeUnmount() {
        this.toggleEveryDisplay();
        this.toggleHideConfig();
        body.classList.add("bg-gray-100");
    },
    methods: {
        ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
    },
};
</script>
